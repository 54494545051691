<template>
  <div>
    <div class="d-flex justify-center">
      <v-card
        outlined
        class="elevation-0 rounded-lg"
        style="max-width: 300px"
        v-if="party && ticketBlock"
        id="form-sell-user"
      >
        <v-card-text class="d-flex flex-column align-center gap-5 pa-4">
          <v-avatar size="50">
            <v-icon x-large>mdi-account-question</v-icon>
          </v-avatar>
          <div>
            <phone-input
              :phone="user.phone"
              @update:phone="(e) => (user.phone = e)"
              :ddi="user.ddi"
              @update:ddi="(e) => (user.ddi = e)"
              @valid="(v) => (valid = v)"
              dense
              outlined
              label="WhatsApp do Comprador"
              :disabled="loading"
            />
            <v-btn
              :disabled="!valid || loading"
              @click="getUserInfo"
              color="primary"
              :loading="loading"
              block
            >
              Próximo
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-alert
      v-if="error"
      border="left"
      class="text-left mt-2 mx-4"
      text
      icon="mdi-alert-circle-outline"
      type="error"
    >
      {{ error }}
    </v-alert>

    <div style="width: 100%; display: flex" class="mt-2">
      <v-btn text @click="$emit('prev')" :disabled="loading" small>
        <v-icon>mdi-chevron-left</v-icon>
        voltar
      </v-btn>
      <v-spacer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PhoneInput from "../../../../components/global/PhoneInput.vue";
import TICKET from "@/services/admin/ticket";

export default {
  emit: ["input"],
  components: { PhoneInput },
  data: () => ({
    valid: false,
    loading: false,
    error: null,
    user: { phone: "", ddi: 55 },
  }),
  methods: {
    async getUserInfo() {
      try {
        this.loading = true;
        this.error = null;
        const { user, membershipPrice } = await TICKET.sellVerify(
          this.selectedOrganization.id,
          this.party.id,
          {
            ticketBlock: this.ticketBlock.id,
            user: {
              phone: this.user.phone,
              ddi: this.user.ddi,
            },
          }
        );
        this.$emit("input", user);
        this.$emit("membershipPlan", membershipPrice);
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.user = { phone: "", ddi: 55 };
      this.error = null;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {},
  props: {
    value: {
      type: Object | null,
    },
    party: {
      type: Object,
      default: () => null,
    },
    ticketBlock: {
      type: Object,
      default: () => null,
    },
  },
  mounted() {
    this.$root.$on("sell-reset", this.reset);
  },
};
</script>

<style></style>
