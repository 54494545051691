<template>
  <div>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <template v-else>
      <v-progress-linear indeterminate v-if="loading"> </v-progress-linear>
      <div v-else-if="parties.length == 0" class="text-center">
        <v-avatar color="grey lighten-4 mt-16" size="50">
          <v-icon x-large class="display-1 grey darken-1">mdi-ticket</v-icon>
        </v-avatar>
        <h5 class="mb-0 mt-4">Nenhuma venda realizada</h5>
      </div>
      <v-row v-else>
        <v-col cols="12" sm="6" lg="4" v-for="party in parties" :key="party.id">
          <my-sales-party-card :party="party" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";
import MySalesPartyCard from "../../components/admin/organization/mySales/MySalesPartyCard.vue";
export default {
  components: { MySalesPartyCard },
  metaInfo() {
    return { title: "Minhas vendas" };
  },
  data: () => ({
    loading: false,
    error: null,
    parties: [],
  }),
  methods: {
    async getMySales() {
      try {
        this.loading = true;
        const { parties } = await ORGANIZATION.mySales(
          this.selectedOrganization.id
        );
        this.parties = parties;
      } catch (err) {
        this.error = err.message || "Ocorreu um erro ao carregar suas vendas";
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
  },
  watch: {
    "selectedOrganization.id"() {
      this.getMySales();
    },
  },
  mounted() {
    this.getMySales();
  },
};
</script>

<style></style>
