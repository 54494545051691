<template>
  <v-alert
    v-if="!!alerts"
    text
    border="left"
    :type="alerts.type || 'info'"
    class="mb-4"
  >
    <strong>Preencha os dados para completar o cadastro:</strong>
    <ul>
      <li v-for="(alert, i) in alerts.alerts" :key="i">{{ alert.message }}</li>
    </ul>
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";
const validations = [
  {
    type: "error",
    message: "Conecte sua conta do Mercado Pago",
    condition: (organization) => !organization.mercadoPagoAccount,
  },
  {
    type: "warning",
    message: "Foto de Perfil",
    condition: (organization) => !organization.photo,
  },
  {
    type: "warning",
    message: "Foto de Capa",
    condition: (organization) => !organization.cover,
  },
  {
    type: "warning",
    message: "Redes Sociais",
    condition: (organization) =>
      !organization.socialNetworks || !organization.socialNetworks.length,
  },
  {
    type: "warning",
    message: "Descrição",
    condition: (organization) => !organization.description,
  },
];

export default {
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    alerts() {
      let alerts = validations.filter((validation) =>
        validation.condition(this.selectedOrganization)
      );
      if (!alerts.length) return false;
      let type = alerts.some((alert) => alert.type === "error")
        ? "error"
        : alerts.some((alert) => alert.type === "warning")
        ? "warning"
        : "success";

      return { type, alerts };
    },
  },
};
</script>

<style></style>
