var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"form-sell-ticket"}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"mb-4",attrs:{"indeterminate":""}}):_vm._e(),(_vm.ticketGroups.length)?_c('ColsOrganizer',{attrs:{"cols":"1","sm":"2","md":"3","items":_vm.ticketGroupsMapped,"itemKey":"id","gap":1},scopedSlots:_vm._u([{key:"default",fn:function({ item: tg }){return [_c('v-card',{staticClass:"pa-4 rounded-lg",attrs:{"outlined":""}},[_c('div',[(tg.tableGroupId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"float-right",attrs:{"small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-chair")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(tg.TableGroup.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Lugares marcados ")])]):_vm._e(),_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(tg.name))]),_c('DescriptionWrap',{attrs:{"description":tg.description,"lines":2.5}}),(tg.tableGroupId && tg.TableGroup.capacity > 1)?_c('v-alert',{attrs:{"type":"info","color":"primary","dense":"","text":""}},[_vm._v(" Compras neste setor dão direito "),_c('b',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(tg.TableGroup.capacity)+" ingressos ")])]):_vm._e()],1),_vm._l((tg.TicketBlock),function(tb){return _c('v-card',{key:tb.id,attrs:{"disabled":!tb.startDate || new Date(tb.startDate) > new Date(),"elevation":"0"},on:{"click":function($event){return _vm.selectTicket(tb, tg)}}},[_c('v-alert',{staticClass:"mt-2 mb-0",attrs:{"color":tb.startDate && new Date(tb.startDate) < new Date()
                ? 'primary'
                : 'secondary',"outlined":"","dense":"","text":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-1 gap-2"},[_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(tb.name))]),_c('div',{staticClass:"d-flex align-center gap-2"},[_c('b',{class:{
                      'text-18': !tb.promotion,
                      'text--disabled': tb.promotion,
                      'text-decoration-line-through': tb.promotion,
                    }},[_vm._v(" "+_vm._s(_vm._f("currency")(tb.price,true))+" ")]),(tb.promotion)?[_c('b',{staticClass:"text-18"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calculateTicketPricePromotion(tb, tb.promotion),true))+" ")]),_c('v-chip',{attrs:{"label":"","color":"success","small":""}},[_vm._v(" -"),(tb.promotion.data.discountType == 'percentage')?[_vm._v(" "+_vm._s(tb.promotion.data.discount + '%')+" ")]:[_vm._v(" "+_vm._s(_vm._f("currency")(tb.promotion.data.discount,true))+" ")]],2)]:_vm._e(),(tb.TicketBlockMembership.length)?[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-card-account-details-star ")])]:_vm._e()],2)]),(!tb.startDate || new Date(tb.startDate) > new Date())?_c('div',{staticClass:"text-end"},[_c('small',[_vm._v(" Em breve ")])]):(_vm.hasPermission(16))?_c('div',{staticClass:"d-flex flex-column text-end"},[_c('span',[_vm._v(" "+_vm._s(tb.tickets.remaining)+" ingresso"+_vm._s(tb.tickets.remaining != 1 ? "s" : "")+" ")]),(tb.tickets.pending != 0)?_c('small',{staticClass:"text--disabled mb-0"},[_vm._v(" "+_vm._s(tb.tickets.pending)+" pendente"+_vm._s(tb.tickets.pending != 1 ? "s" : "")+" ")]):_vm._e()]):_vm._e()])])],1)})],2)]}}],null,false,2496848626)}):(!_vm.loading)?[_c('div',{staticClass:"text-center"},[_c('v-avatar',{attrs:{"color":"grey lighten-4","size":"50"}},[_c('v-icon',{staticClass:"display-1 grey darken-1",attrs:{"x-large":""}},[_vm._v(" mdi-ticket ")])],1),_c('p',{staticClass:"text--disabled mt-2"},[_vm._v(" Não há ingressos disponíveis para esse evento ")])],1)]:_vm._e(),(_vm.hasPermission(16))?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"info"}},[_c('div',{staticClass:"d-flex justify-space-between align-center flex-wrap gap-y-2"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" Você pode gerenciar os ingressos disponíveis para venda ")])]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({
            name: 'admin.party.management',
            params: { partyId: _vm.party.id },
            hash: '#tickets',
          })}}},[_vm._v(" Gerenciar ")])],1)]):_vm._e(),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","disabled":_vm.loading,"small":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" voltar ")],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }