<template>
  <v-card elevation="0" class="rounded-lg pa-4">
    <div>
      <h6>Slug</h6>
      <p>
        O slug é o nome que aparece na URL da organização. Não é possível
        alterar o slug depois de criado.
      </p>
      <v-text-field
        v-model="slug"
        :placeholder="defaultSlug"
        prefix="https://atleticme.app/shop/"
        outlined
        dense
        hide-details=""
      />
      <v-btn
        v-if="!!defaultSlug"
        small
        block
        :disabled="!hasChanges || !slug"
        color="success"
        @click="save"
        class="mt-2"
      >
        Salvar
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";
import slugify from "@/utils/slugfy";
export default {
  data() {
    return {
      loading: false,
      error: false,
      slug: "",
      defaultSlug: "",
    };
  },
  methods: {
    setSlug() {
      const regex = /-([\d\w]{9})$/;
      const isDefault = regex.test(this.selectedOrganization.slug);
      this.slug = isDefault ? "" : this.selectedOrganization.slug;
      this.defaultSlug = isDefault ? this.selectedOrganization.slug : "";
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        await ORGANIZATION.updateSlug(
          this.selectedOrganization.id,
          slugify(this.slug.trim())
        );
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao salvar a descrição";
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    "selectedOrganization.slug"(value) {
      this.setSlug();
    },
    slug(value) {
      this.slug = slugify(value);
    },
  },
  mounted() {
    this.setSlug();
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    hasChanges() {
      return this.slug !== this.selectedOrganization.slug;
    },
  },
};
</script>

<style></style>
