<template>
  <base-card class="rounded-xl" :loading="loading">
    <v-stepper v-model="step" elevation="0" class="rounded-xl">
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="step > 1" step="1">
          Evento
          <span class="text--disabled caption ml-2">
            {{ selectedParty ? selectedParty.name : "" }}
          </span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Ingresso
          <span class="text--disabled caption ml-2">
            {{
              selectedTicketBlock
                ? selectedTicketBlock.ticketGroup.name +
                  " | " +
                  selectedTicketBlock.name
                : ""
            }}
          </span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="step > 3">
          Identificação
        </v-stepper-step>
        <v-divider></v-divider>
        <template v-if="selectedTicketBlock?.ticketGroup?.tableGroupId">
          <v-stepper-step step="4" :complete="step > 4"> Lugar </v-stepper-step>
          <v-divider></v-divider>
        </template>
        <v-stepper-step step="5"> Finalização </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <sell-select-party v-model="selectedParty" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <sell-select-ticket
            v-model="selectedTicketBlock"
            :party="selectedParty"
            @prev="step--"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <sell-user
            v-model="user"
            :party="selectedParty"
            :ticketBlock="selectedTicketBlock"
            @membershipPlan="membershipPlan = $event"
            @prev="step--"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <sell-select-table
            v-model="selectedTable"
            :party="selectedParty"
            :ticketBlock="selectedTicketBlock"
            :user="user"
            @prev="step = 3"
            @reset="reset"
          />
        </v-stepper-content>
        <v-stepper-content step="5">
          <sell-finish
            :party="selectedParty"
            :ticketBlock="selectedTicketBlock"
            :table="selectedTable"
            :user="user"
            :membershipPlan="membershipPlan"
            @prev="step = 3"
            @reset="reset"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";

import SellSelectParty from "./modules/SellSelectParty.vue";
import SellSelectTicket from "./modules/SellSelectTicket.vue";
import SellUser from "./modules/SellUser.vue";
import SellFinish from "./modules/SellFinish.vue";
import SellSelectTable from "./modules/SellSelectTable.vue";

export default {
  components: {
    SellSelectParty,
    SellSelectTicket,
    SellUser,
    SellFinish,
    SellSelectTable,
  },
  metaInfo: {
    title: "Vender",
  },

  data: () => ({
    step: 1,
    loading: false,
    error: false,
    selectedParty: null,
    selectedTicketBlock: null,
    selectedTable: null,
    user: { phone: "", ddi: 55 },
    membershipPlan: null,
    valid: false,
  }),

  methods: {
    reset() {
      this.step = 1;
      this.selectedParty = null;
      this.selectedTicketBlock = null;
      this.user = { phone: "", ddi: 55 };
      this.membershipPlan = null;
      this.$root.$emit("sell-reset");
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["hasPermission"]),
  },
  watch: {
    step(val) {
      switch (val) {
        case 1:
          this.$root.$emit("sell-step:party");
          this.selectedParty = null;
          break;
        case 2:
          this.$root.$emit("sell-step:ticket");
          this.selectedTicketBlock = null;
          this.selectedTable = null;
          break;
        case 3:
          this.$root.$emit("sell-step:user");
          this.user = { phone: this.user.phone, ddi: 55 };
          this.selectedTable = null;
          break;
      }
    },
    user(val) {
      if (!val.phone || !val.ddi || !val.id) return;
      console.log(this.selectedTicketBlock);
      if (this.selectedTicketBlock?.ticketGroup?.tableGroupId) this.step = 4;
      else this.step = 5;
    },
    selectedParty(val) {
      if (!val) {
        this.$root.$emit("setImgBg", null);
        return;
      }
      this.$root.$emit("setImgBg", val.cover);
      this.step = 2;
    },
    selectedTicketBlock(val) {
      if (!val) return;
      this.step = 3;
    },
    selectedTable(val) {
      if (!val) return;
      this.selectedTable = val;
      this.step = 5;
    },
    "selectedOrganization.id"(val) {
      if (!val) return;
      this.reset();
    },
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
};
</script>

<style></style>
