<template>
  <div id="form-sell-ticket">
    <v-progress-linear class="mb-4" indeterminate v-if="loading" />
    <ColsOrganizer
      v-if="ticketGroups.length"
      cols="1"
      sm="2"
      md="3"
      :items="ticketGroupsMapped"
      itemKey="id"
      :gap="1"
    >
      <template v-slot="{ item: tg }">
        <v-card outlined class="pa-4 rounded-lg">
          <div>
            <v-tooltip v-if="tg.tableGroupId" top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip small v-bind="attrs" v-on="on" class="float-right">
                  <v-icon small>mdi-table-chair</v-icon>
                  <span class="ml-1"> {{ tg.TableGroup.name }} </span>
                </v-chip>
              </template>
              <span> Lugares marcados </span>
            </v-tooltip>
            <h5 class="mb-1">{{ tg.name }}</h5>
            <DescriptionWrap :description="tg.description" :lines="2.5" />
            <v-alert
              type="info"
              color="primary"
              v-if="tg.tableGroupId && tg.TableGroup.capacity > 1"
              dense
              text
            >
              Compras neste setor dão direito
              <b class="font-weight-bold">
                {{ tg.TableGroup.capacity }} ingressos
              </b>
            </v-alert>
          </div>
          <v-card
            v-for="tb in tg.TicketBlock"
            :key="tb.id"
            :disabled="!tb.startDate || new Date(tb.startDate) > new Date()"
            @click="selectTicket(tb, tg)"
            elevation="0"
          >
            <v-alert
              class="mt-2 mb-0"
              :color="
                tb.startDate && new Date(tb.startDate) < new Date()
                  ? 'primary'
                  : 'secondary'
              "
              outlined
              dense
              text
            >
              <div class="d-flex justify-space-between align-center mb-1 gap-2">
                <div>
                  <h6 class="mb-0">{{ tb.name }}</h6>
                  <div class="d-flex align-center gap-2">
                    <b
                      :class="{
                        'text-18': !tb.promotion,
                        'text--disabled': tb.promotion,
                        'text-decoration-line-through': tb.promotion,
                      }"
                    >
                      {{ tb.price | currency(true) }}
                    </b>
                    <template v-if="tb.promotion">
                      <b class="text-18">
                        {{
                          calculateTicketPricePromotion(tb, tb.promotion)
                            | currency(true)
                        }}
                      </b>
                      <v-chip label color="success" small>
                        -<template v-if="tb.promotion.data.discountType == 'percentage'">
                          {{
                            tb.promotion.data.discount + '%'
                          }}
                        </template>
                        <template v-else>
                          {{
                            tb.promotion.data.discount | currency(true)
                          }}
                        </template>
                      </v-chip>
                    </template>
                    <template v-if="tb.TicketBlockMembership.length">
                      <v-icon small color="primary">
                        mdi-card-account-details-star
                      </v-icon>
                    </template>
                  </div>
                </div>
                <div
                  v-if="!tb.startDate || new Date(tb.startDate) > new Date()"
                  class="text-end"
                >
                  <small> Em breve </small>
                </div>
                <div
                  class="d-flex flex-column text-end"
                  v-else-if="hasPermission(16)"
                >
                  <span>
                    {{ tb.tickets.remaining }} ingresso{{
                      tb.tickets.remaining != 1 ? "s" : ""
                    }}
                  </span>
                  <small
                    v-if="tb.tickets.pending != 0"
                    class="text--disabled mb-0"
                  >
                    {{ tb.tickets.pending }} pendente{{
                      tb.tickets.pending != 1 ? "s" : ""
                    }}
                  </small>
                </div>
              </div>
            </v-alert>
          </v-card>
        </v-card>
      </template>
    </ColsOrganizer>
    <template v-else-if="!loading">
      <div class="text-center">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1"> mdi-ticket </v-icon>
        </v-avatar>
        <p class="text--disabled mt-2">
          Não há ingressos disponíveis para esse evento
        </p>
      </div>
    </template>

    <v-alert v-if="hasPermission(16)" type="info" class="mt-4">
      <div class="d-flex justify-space-between align-center flex-wrap gap-y-2">
        <div>
          <p class="mb-0">
            Você pode gerenciar os ingressos disponíveis para venda
          </p>
        </div>
        <v-btn
          small
          @click="
            $router.push({
              name: 'admin.party.management',
              params: { partyId: party.id },
              hash: '#tickets',
            })
          "
        >
          Gerenciar
        </v-btn>
      </div>
    </v-alert>
    <v-btn class="mt-2" text @click="$emit('prev')" :disabled="loading" small>
      <v-icon>mdi-chevron-left</v-icon> voltar
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import DescriptionWrap from "../../../../components/global/DescriptionWrap.vue";
import ColsOrganizer from "../../../../components/global/ColsOrganizer.vue";

export default {
  components: { DescriptionWrap, ColsOrganizer },
  emit: ["input"],
  data: () => ({
    loading: false,
    ticketGroups: [],
    promotions: [],

    ticketGroupPanel: null,
    tableHeaders: [
      { text: "Nome", value: "name" },
      { text: "Valor", value: "price" },
      { text: "Disponíveis", value: "remaining" },
      { text: "", value: "action", sortable: false, align: "right" },
    ],
  }),
  methods: {
    selectTicket(ticketBlock, ticketGroup) {
      this.$emit("input", { ...ticketBlock, ticketGroup });
    },
    async getTickets() {
      try {
        this.loading = true;
        this.$emit("input", null);
        this.ticketGroups = [];

        const response = await TICKET.canSell(
          this.selectedOrganization.id,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
        this.promotions = response.promotions;
        if (response.ticketGroups.length == 1) this.ticketGroupPanel = 0;
      } catch (error) {
        console.log(error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    calculateTicketPricePromotion(ticketBlock, promotion) {
      if (promotion.data.discountType == "percentage")
        return (
          ticketBlock.price -
          (ticketBlock.price * promotion.data.discount) / 100
        );
      else return Math.max(0, ticketBlock.price - promotion.data.discount);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),

    mappedPromotions() {
      var ticketBlockPromotions = this.promotions.reduce((acc, promotion) => {
        promotion.data.ticketBlocks.forEach((tb) => {
          if (!acc[tb]) acc[tb] = [];
          acc[tb].push({
            ...promotion,
            price: this.calculateTicketPricePromotion(tb, promotion),
          });
        });
        return acc;
      }, {});

      return Object.fromEntries(
        Object.entries(ticketBlockPromotions).map(
          ([ticketBlock, promotions]) => {
            return [
              ticketBlock,
              promotions.sort((a, b) => a.price - b.price)[0],
            ];
          }
        )
      );
    },
    ticketGroupsMapped() {
      return this.ticketGroups.map((tg) => ({
        ...tg,
        TicketBlock: tg.TicketBlock.sort((a, b) => {
          if (!a.startDate) return 1;
          return a.price - b.price;
        }).map((tb) => ({
          ...tb,
          promotion: this.mappedPromotions[tb.id],
        })),
      }));
    },
  },
  watch: {
    party: {
      handler: function (val) {
        if (val) this.getTickets();
        else this.ticketGroups = [];
      },
      deep: true,
    },
  },
  props: {
    value: {
      type: String | null,
      default: () => null,
    },
    party: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style></style>
