<template>
  <v-card elevation="0" :loading="loading" class="rounded-lg">
    <div class="pa-4">
      <h6>Taxas da Atletic.Me</h6>
      <div class="d-flex flex-column gap-2">
        <v-alert v-if="error" type="error" class="mb-0">
          {{ error }}
        </v-alert>
        <v-card
          v-for="[type, fees] in mappedFees"
          :key="type"
          outlined
          class="rounded-lg pa-4 d-flex align-center gap-4"
        >
          <v-icon large>{{ types[type].icon }}</v-icon>
          <div>
            <h6 class="mb-0">
              {{ types[type].label }}
            </h6>
            <span v-if="fees.hasLowest" class="text-caption">
              Desconto válido até {{ fees.lowest.endAt | date }}
            </span>
          </div>
          <v-spacer />
          <div class="d-flex flex-wrap justify-end align-center">
            <span
              :class="{
                'text-decoration-line-through': fees.hasLowest,
                'text-h6': fees.hasLowest,
                'font-weight-bold': !fees.hasLowest,
                'text-h5': !fees.hasLowest,
                'success--text': !fees.default.value,
              }"
            >
              {{ fees.default.value }}%
            </span>
            <span
              v-if="fees.hasLowest"
              class="text-h5 pl-2 font-weight-bold success--text"
            >
              {{ fees.lowest.value }}%
            </span>
          </div>
        </v-card>
        <v-divider />
        <v-card
          elevation="0"
          class="rounded-lg pa-4 d-flex flex-column gap-2"
          outlined
        >
          <h6 class="mb-0">Venda online</h6>
          <span class="text-caption">
            As vendas online de ingressos e filiações têm uma taxa de
            processamento do Mercado Pago, que varia de acordo com o método de
            pagamento e o prazo de recebimento escolhido. Esta taxa, combinada
            com a da Atletic.Me, é aplicada sobre o valor total da venda e cobrada
            automaticamente pelo Mercado Pago.
          </span>
          <div>
            <v-btn
              small
              block
              color="info"
              href="https://www.mercadopago.com.br/costs-section/release-options/edit/merchant-services"
              target="_blank"
            >
              Ver taxas do Mercado Pago
            </v-btn>
            <small class="text-center d-block">
              É necessário estar logado na conta do Mercado Pago para visualizar
            </small>
          </div>
        </v-card>
        <v-card elevation="0" class="rounded-lg pa-4" outlined>
          <h6 class="mb-0">Venda offline</h6>
          <span class="text-caption">
            Para as vendas de ingressos e filiações offline você paga apenas a
            taxa da Atletic.Me. Veja abaixo os valores.
          </span>

          <v-alert
            :color="offlineFeePayments?.balance >= 0 ? 'success' : 'warning'"
            border="right"
            class="mb-0"
            dense
            text
          >
            <div class="d-flex justify-space-between align-center">
              <template v-if="offlineFeePayments?.balance == 0">
                <span class="font-weight-bold">
                  Você não possui saldo devedor
                </span>
              </template>
              <template v-else>
                <template v-if="offlineFeePayments?.balance > 0">
                  <span class="font-weight-bold">Crédito em taxa:</span>
                </template>
                <template v-else>
                  <span class="font-weight-bold">Saldo devedor</span>
                </template>

                <v-divider class="mx-2"></v-divider>
                <span class="font-weight-bold text-h6 mb-0">
                  {{ Math.abs(offlineFeePayments?.balance) | currency(true) }}
                </span>
              </template>
            </div>
          </v-alert>
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    fees: [],
    offlineFeePayments: null,
    loading: false,
    error: false,
    types: {
      TICKET: {
        label: "Ingresso",
        icon: "mdi-ticket",
      },
      MEMBERSHIP: {
        label: "Filiação",
        icon: "mdi-card-account-details-star",
      },
    },
  }),
  methods: {
    async getFees() {
      try {
        this.loading = true;
        this.error = false;
        const { fees } = await ORGANIZATION.fees(this.selectedOrganization.id);
        const offlineFeePayments = await ORGANIZATION.feePayments(
          this.selectedOrganization.id
        );
        this.fees = fees;
        this.offlineFeePayments = offlineFeePayments;
      } catch (e) {
        this.error = e.message || "Erro ao carregar taxas";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    mappedFees() {
      const reduceByType = this.fees
        .sort((a, b) => a.value - b.value)
        .reduce((acc, fee) => {
          if (fee.partyId) return acc;
          const isDefault = !fee.organizationId;
          const { type } = fee;

          if (acc[type]) {
            if (acc[type].lowest?.value > fee.value) {
              acc[type].lowest = fee;
              acc[type].hasLowest = true;
            }
          } else
            acc[type] = {
              default: null,
              lowest: fee,
              hasLowest: false,
            };

          if (isDefault) acc[type].default = fee;
          else acc[type].hasLowest = true;

          return acc;
        }, {});
      return Object.entries(reduceByType);
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.fees = [];
      this.getFees();
    },
  },
  mounted() {
    this.getFees();
  },
};
</script>

<style></style>
