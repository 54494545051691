<template>
  <div>
    <v-row v-if="!!party && !!ticketBlock">
      <v-col cols="12" sm="6" md="5" lg="4">
        <v-card outlined class="rounded-lg">
          <v-img
            class="rounded-lg rounded-b-0"
            :src="party.cover"
            :aspect-ratio="16 / 9"
          />
          <div class="d-flex flex-column gap-2 pa-4">
            <div class="">
              <span class="text-overline lh-1" style="">
                {{ party.date | date("DD [de] MMMM - HH:mm") }}
              </span>
              <h4 class="text-20 mb-0">{{ party.name }}</h4>
            </div>
            <div class="d-flex justify-space-between align-center gap-2">
              <h5 class="text-16 mb-0">{{ ticketBlock.ticketGroup.name }}</h5>
              <v-divider />
              <h5 class="text-16 text-end mb-0">{{ ticketBlock.name }}</h5>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="7" lg="8">
        <div class="d-flex flex-column gap-2 justify-space-between h-full">
          <div>
            <v-card outlined class="rounded-lg pa-4">
              <h6>Comprador</h6>
              <div outlined class="d-flex gap-2">
                <v-avatar v-if="!user.name" size="50">
                  <v-icon x-large>mdi-account-question</v-icon>
                </v-avatar>
                <base-avatar v-else :size="50" :seed="user.id || ''" />
                <div>
                  <h6 class="mb-0">{{ user.name || "Conta não criada" }}</h6>
                  <small>{{ user.phone }}</small>
                </div>
                <v-spacer />
                <v-chip
                  v-if="!!membershipPlan"
                  :color="membershipPlan.Membership.backgroundColor"
                  text-color="white"
                  label
                  class="font-weight-medium"
                  :style="{
                    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                      membershipPlan.Membership.backgroundImg
                    }), ${
                      membershipPlan.Membership.backgroundColor || '#3333'
                    }80`,
                    backgroundSize: 'cover',
                    border: `1px solid ${
                      membershipPlan.Membership.backgroundColor || '#000'
                    }`,
                    boxShadow: `0 0 10px  ${
                      membershipPlan.Membership.backgroundColor ||
                      'rgba(0,0,0,.2)'
                    }`,
                  }"
                >
                  {{ membershipPlan.Membership.name }}
                </v-chip>
              </div>
            </v-card>
            <v-card
              v-if="table"
              outlined
              class="d-flex pa-3 gap-2 mt-2 align-center rounded-lg"
            >
              <v-icon>mdi-table-chair</v-icon>
              <h6 class="mb-0 flex-grow-1">
                {{ table.group.name }} • {{ table.name }}
              </h6>
              <v-chip small class="font-weight-bold">
                {{ table.group.capacity }} pessoa{{
                  table.group.capacity > 1 ? "s" : ""
                }}
              </v-chip>
            </v-card>
          </div>
          <v-card outlined class="rounded-lg pa-4">
            <div class="d-flex flex-column gap-2 text-15">
              <div
                class="d-flex gap-5 align-center justify-space-between"
                v-if="!!membershipPlan"
              >
                <label>Valor</label>
                <b>
                  {{ ticketBlock.price | currency(true) }}
                </b>
              </div>
              <div
                v-if="!!membershipPlan"
                class="d-flex gap-5 align-center justify-space-between"
              >
                <label>Desconto Sócio</label>
                <b class="ml-3">{{
                  (membershipPlan.price - ticketBlock.price) | currency(true)
                }}</b>
              </div>
              <div
                v-if="!!promotionDiscount"
                class="d-flex gap-5 align-center justify-space-between"
              >
                <label>Desconto</label>
                <b class="ml-3">{{ promotionDiscount | currency(true) }}</b>
              </div>
              <div
                class="d-flex gap-5 align-center justify-space-between text-17"
              >
                <label class="font-weight-medium">Total</label>
                <b>{{ ticketPrice | currency(true) }}</b>
              </div>

              <v-divider />
              <div class="d-flex align-center justify-space-between">
                <p class="mb-0 font-weight-bold">Forma de pagamento</p>
                <v-radio-group
                  v-model="paymentMethod"
                  row
                  class="mt-0"
                  hide-details
                >
                  <v-radio
                    v-for="method in paymentMethods"
                    :key="method.value"
                    :value="method.value"
                    :label="method.title"
                    class="ml-4 mr-0"
                  >
                    <template v-slot:label>
                      {{ method.title }}
                      <v-chip
                        label
                        x-small
                        color="success"
                        class="ml-2"
                        v-if="
                          ticketBlock?.promotion &&
                          ticketBlock.promotion.data.paymentMethods.includes(
                            method.value
                          )
                        "
                      >
                        -<template
                          v-if="
                            ticketBlock?.promotion.data.discountType ==
                            'percentage'
                          "
                        >
                          {{ ticketBlock.promotion.data.discount + "%" }}
                        </template>
                        <template v-else>
                          {{
                            ticketBlock.promotion.data.discount | currency(true)
                          }}
                        </template>
                      </v-chip>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-alert type="info" color="primary" v-if="paymentMethod" border="left">
          <template v-if="paymentMethod == 'online'">
            Um link será enviado ao WhatsApp do cliente para concluir a compra.
            O link terá validade de 30 minutos para finalizar o pedido.
          </template>
          <template v-else-if="paymentMethod == 'offline'">
            <b>
              Receba
              {{ ticketPrice | currency(true) }}
              agora
            </b>
            do cliente e finalize a compra. O ingresso será enviado
            automaticamente para o WhatsApp do cliente.
            <p class="text-14 mb-0">
              Essa compra ficará registada para acerto futuro.
            </p>
          </template>
        </v-alert>
        <v-alert type="error" text v-if="error" border="left">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>

    <div style="width: 100%; display: flex">
      <v-btn text @click="$emit('prev')" :disabled="loading" icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="!paymentMethod"
        color="success"
        @click="finish"
      >
        Finalizar • {{ ticketPrice | currency(true) }}
      </v-btn>
    </div>
    <money-sell-confirm @close="sellMoney" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";

import MoneySellConfirm from "@/components/admin/modals/MoneySellConfirm.vue";

export default {
  emit: ["prev"],
  components: { MoneySellConfirm },
  data: () => ({
    error: false,
    loading: false,
    paymentMethod: null,
  }),
  methods: {
    finish() {
      if (this.paymentMethod == "online") this.sell();
      else if (this.paymentMethod == "offline") this.confirmSellMoney();
    },
    confirmSellMoney() {
      this.$root.$emit("confirm-money-sell", {
        party: this.party,
        ticketGroup: this.ticketBlock.ticketGroup,
        ticket: this.ticketBlock,
        user: this.user,
        membershipPlan: this.membershipPlan,
        ticketPrice: this.ticketPrice,
      });
    },
    async sellMoney(result) {
      if (!result) return;
      try {
        this.loading = true;
        this.error = false;

        const response = await TICKET.sellMoney(
          this.selectedOrganization.id,
          this.party.id,
          {
            ticketBlock: this.ticketBlock.id,
            tableId: this.table?.id,
            user: this.user,
            value: this.ticketPrice,
          }
        );

        await this.confetti();

        this.$emit("reset");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async sell() {
      try {
        this.loading = true;
        this.error = false;

        const response = await TICKET.sell(
          this.selectedOrganization.id,
          this.party.id,
          {
            ticketBlock: this.ticketBlock.id,
            tableId: this.table?.id,
            user: this.user,
          }
        );

        this.confetti();

        this.$emit("reset");
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async confetti() {
      this.$confetti.start();
      await new Promise((r) => setTimeout(r, 1500));
      this.$confetti.stop();
    },
    calculateTicketPricePromotion(price, promotion) {
      if (promotion.data.discountType == "percentage")
        return price - (price * promotion.data.discount) / 100;
      else return Math.max(0, price - promotion.data.discount);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    paymentMethods() {
      var methods = [
        {
          title: "Online",
          value: "online",
          permission: 32,
        },
        {
          title: "Presencial",
          value: "offline",
          permission: 1024,
        },
      ];
      const r = methods.filter((method) =>
        this.hasPermission(method.permission)
      );
      if (r.length == 1) this.paymentMethod = r[0].value;
      return r;
    },
    membershipPrice() {
      const membershipPrice = this.membershipPlan?.price || 0;
      return membershipPrice;
    },
    promotionDiscount() {
      if (!this.ticketBlock?.promotion) return 0;
      if (
        !this.ticketBlock.promotion.data.paymentMethods.includes(
          this.paymentMethod
        )
      )
        return 0;
      const price = this.membershipPrice || this.ticketBlock.price;
      var promotionPrice = 0;
      if (this.ticketBlock?.promotion) {
        promotionPrice = this.calculateTicketPricePromotion(
          price,
          this.ticketBlock.promotion
        );
      }
      return promotionPrice - price;
    },
    ticketPrice() {
      const price = this.membershipPrice || this.ticketBlock.price;
      return Math.max(0, price + this.promotionDiscount);
    },
  },
  watch: {},
  props: {
    membershipPlan: {
      type: Object | null,
      required: true,
    },
    user: {
      type: Object | null,
      required: true,
    },
    party: {
      type: Object | null,
      required: true,
    },
    ticketBlock: {
      type: Object | null,
      required: true,
    },
    table: {
      type: Object | null,
      required: true,
    },
  },
};
</script>

<style></style>
