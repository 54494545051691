<template>
  <v-dialog v-model="dialog" persistent width="380px">
    <v-card v-if="!!data">
      <v-card-title>Venda em dinheiro</v-card-title>
      <v-card-text class="pb-0">
        <v-alert
          color="primary"
          border="bottom"
          text
          class="text-center font-weight-bold"
        >
          <p class="lh-1">Receba do Cliente</p>
          <span class="font-weight-bold text-24">
            {{ data.ticketPrice | currency }}
          </span>
        </v-alert>
        <v-alert dense color="secondary" class="text-13">
          Essa ingresso ficará disponível para o cliente
          <b class="font-weight-bold">imediatamente</b>. O valor da venda ficará
          registado para recolhimento no futuro.
        </v-alert>
        <h6 class="text-center mb-0">Confirmar venda?</h6>
      </v-card-text>
      <v-card-actions class="justify-space-between pb-4">
        <v-btn text @click="close(false)"> Cancelar </v-btn>
        <v-btn color="primary" @click="close(true)"> Confirmar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    data: null,
  }),
  methods: {
    open(data) {
      this.dialog = true;
      this.data = data;
    },
    close(type = false) {
      this.dialog = false;
      this.$emit("close", type);
      this.$nextTick(() => {
        this.data = null;
      });
    },
  },
  mounted() {
    this.$root.$on("confirm-money-sell", this.open);
  },
};
</script>

<style></style>
