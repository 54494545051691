<template>
  <v-card class="rounded-lg pa-3" elevation="0">
    <h6>Vendas por evento</h6>
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: false, wheelPropagation: false }"
      class="ps scroll rounded-0"
      style="max-height: 255px; width: 100%"
    >
      <div class="d-flex flex-column gap-2">
        <v-card
          v-for="item in data.parties"
          :key="item.id"
          class="rounded-lg"
          elevation="0"
          outlined
          c
        >
          <div class="d-flex justify-space-between align-top px-3 pr-1 pt-3">
            <b>{{ item.name }}</b>
            <v-btn text x-small @click="report(item.id)">Ver relatório</v-btn>
          </div>
          <div class="px-3 pb-3">
            <div class="d-flex flex-wrap align-center">
              {{ item.sold.count }}
              <v-divider class="mx-2" />
              <span> {{ item.sold.amount | currency(true) }}</span>
            </div>
            <div v-if="item.pending.count" class="d-flex flex-wrap align-center px-1 text-12">
              {{ item.pending.count }}
              <v-divider class="mx-2" />
              <span>Pendentes</span>
              <v-divider class="mx-2" />
              <span> {{ item.pending.amount | currency(true) }}</span>
            </div>
          </div>
        </v-card>
        <v-card
          v-if="!data.parties.length"
          elevation="0"
          outlined
          class="d-flex align-center justify-center rounded-lg pa-3"
        >
          <p class="mb-0">Nenhuma evento ativo</p>
        </v-card>
      </div>
    </vue-perfect-scrollbar>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    report(id) {
      this.$router.push({
        name: "admin.party.management",
        params: { partyId: id },
        hash: "#reports",
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
